import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { Row, Col, Button, Card, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FormSelect from '../../../common/components/FormSelect';
import ChooseAppointmentType, { doseOptions } from './fields/ChooseAppointmentType';
import CovidTypeAppointment from './fields/CovidTypeAppointment';
import LocalizedMarkdown from './fields/LocalizedMarkdown';
import '../../../common/locales/i18n';
import Slotter from './scheduling/calculator';
import GoogleMapsWidget from '../../../common/components/GoogleMapsWidget';
import AppointmentSlotGroupPill from './scheduling/AppointmentSlotGroupPill';
import AppointmentSlotSelector from './scheduling/AppointmentSlotSelector';
import CollapsableAppointmentSlotGroupSelector from './scheduling/CollapsableAppointmentSlotGroupSelector';
import UserAlreadyExistsModal from './fields/UserAlreadyExistsModal';
import {
  calculateAgeInDays,
  calculateAgeInMonths,
  loadAppointmentSlots,
} from '../../../common/utils';
import { buildCustomizedLabel, buildLocalizedString } from '../../../common/locales/langs';

import RegistrationContext from '../../contexts/RegistrationContext';
import { SectionTitle } from '../SectionComponents';

const appointmentSlotGroupsOptions = (records) => {
  return records.map(({ id, title }) => {
    return {value: id, label: title};
  });
};

export const setInitialStep = ({stepOne, stepTwo}) => {
  let step = 0
  if (!stepOne && stepTwo) {
    step = 1
  } else if (!stepOne && !stepTwo) {
    step = 2
  }
  return step
}

const CardWrapper = ({ requiresCard, children }) => (
  requiresCard ? (
    <Card body className="my-3">
      {children}
    </Card>
  ) : <div className="my-4">{children}</div>
);

export const validateAppointmentSlot = (testGroup) => (values) => {
  const errors = {};
  const appointment = values.appointment
  if (!appointment.appointment_slot_id) errors.appointment_slot = 'registration.appointment_required';
  if (appointment.appointment_type === null) errors.dose = "Specify Dose";
  return errors;
}

export const appointmentSlotGroupDisabled = (_appointmentType, appointmentSlotGroup, appointmentConfigurations=[], refuseCovidVaccination=false) => {
  if (refuseCovidVaccination) return false;
  let configurations = doseOptions(appointmentConfigurations).map(x => x.value);
  if (!configurations.includes("default") && (_appointmentType == "default" || _appointmentType == null)) return false;
  const appointmentType = _appointmentType === "default" ? "initial_vaccine_appointment" : _appointmentType;
  const typesAvailableAtLocation = appointmentSlotGroup
    .test_location
    .appointment_configurations
    .map(x => (x.includes("appointment_type") ? x.split(".")[1] : null))
    .filter(x => x);
  if (typesAvailableAtLocation.length === 0) return false;
  if (typesAvailableAtLocation.includes(appointmentType)) return false;
  return true;
}

const AppointmentSlot = ({
  values,
  errors,
  setFieldValue,
  alwaysCollapse = false,
}) => {
  const { t, i18n } = useTranslation();
  const { testGroup, localFormState, setLocalFormState, appointmentSlotGroupsLoaded } = useContext(RegistrationContext);
  const [showNonOnDemandAppointmentSlotGroups, setShowNonOnDemandAppointmentSlotGroups] = useState(false);
  const [appointmentSlotsLoaded, setAppointmentSlotLoaded] = useState(appointmentSlotGroupsLoaded ? testGroup.appointment_slot_groups.map(x => x.id) : []);

  const showChooseAppointmentType = doseOptions(testGroup.appointment_configurations).length > 0;
  const initialStep = setInitialStep({
    stepOne: testGroup.appointment_configurations.includes("ask_if_covid_appointment"),
    stepTwo: showChooseAppointmentType,
  });

  const [step, setStep] = useState(initialStep);
  const urlParams = new URLSearchParams(window.location.search);

  const [
    appointmentSlotGroupData,
    setAppointmentSlotGroupData
  ] = useState(testGroup.appointment_slot_groups)

  const loadApptSlots = ({id, test_location_id}, setSlots = () => {}) => {
    loadAppointmentSlots({
      accessKey: localFormState.key,
      testGroupSlug: testGroup.slug,
      onLoad: (data) => {
        // update appointment_slots for one AppointmentSlotGroup
        let index = testGroup.appointment_slot_groups.findIndex(x => x.id === data.appointment_slot_groups[0].id)
        testGroup.appointment_slot_groups[index] = data.appointment_slot_groups[0];
        slotter = new Slotter(testGroup);
        setAppointmentSlotGroupData(slotter.appointmentSlotGroups);
        // add the AppointmentSlotGroup to the list so that we don't query more than once
        setAppointmentSlotLoaded([...appointmentSlotsLoaded, id])
        setSlots(data.appointment_slot_groups[0])
      },
      accessCode: localFormState.accessCode,
      testLocationId: test_location_id,
    })
  }

  const setOnDemandAppointment = (appointmentSlotGroup) => {
    if (!appointmentSlotGroup) return;
    const setOnDemand = (location) => {
      setSelectedOnDemandLocation(true)
      setFieldValue('appointment.assigned_appointment_slot_group_title', location.title);
      setFieldValue('appointment_slot_group_id', location.id)
      setFieldValue('appointment.appointment_slot_id', location.appointment_slots[0].id)
    }
    if (!appointmentSlotsLoaded.find(x => x == appointmentSlotGroup.id)) {
      loadApptSlots(appointmentSlotGroup, (location) => setOnDemand(location))
    } else {
      setOnDemand(appointmentSlotGroup)
    }

  }

  let slotter = new Slotter(testGroup);

  const availabilitiesParams = {};
  if (localFormState.key) availabilitiesParams.key = localFormState.key;

  useEffect(() => {
    if (appointmentSlotGroupsLoaded) {
      slotter = new Slotter(testGroup);
      setAppointmentSlotLoaded(
        testGroup.appointment_slot_groups.map((x) => x.id),
      );
      setAppointmentSlotGroupData(slotter.appointmentSlotGroups);
    }
  }, [appointmentSlotGroupsLoaded]);

  const appointmentSlotGroups = appointmentSlotGroupData.filter((appointmentSlotGroup) => {
    if (appointmentSlotGroup.test_location.on_demand) return false;
    if (urlParams.has('location') && parseInt(urlParams.get('location')) !== appointmentSlotGroup.id) return false;

    const year = values['date_of_birth_datetime(1i)'];
    const month = values['date_of_birth_datetime(2i)'] - 1;
    const day = values['date_of_birth_datetime(3i)'];
    const dateOfBirth = new Date(year, month, day);
    const ageInDays = calculateAgeInDays(dateOfBirth);
    const ageInMonths = calculateAgeInMonths(dateOfBirth);

    const minimumAgeInDays = appointmentSlotGroup.minimum_age_in_days;
    const maximumAgeInDays = appointmentSlotGroup.maximum_age_in_days;
    const minimumAgeInMonths = appointmentSlotGroup.minimum_age_in_months;
    const maximumAgeInMonths = appointmentSlotGroup.maximum_age_in_months;

    if (!!minimumAgeInDays && !!minimumAgeInMonths) {
      if (ageInDays < minimumAgeInDays && ageInMonths < minimumAgeInMonths) return false;
    } else if (!!minimumAgeInDays) {
      if (ageInDays < minimumAgeInDays) return false;
    } else if (!!minimumAgeInMonths) {
      if (ageInMonths < minimumAgeInMonths) return false;
    }

    if (!!maximumAgeInDays && !!maximumAgeInMonths) {
      if (ageInDays > maximumAgeInDays && ageInMonths > maximumAgeInMonths) return false;
    } else if (!!maximumAgeInDays) {
      if (ageInDays > maximumAgeInDays) return false;
    } else if (!!maximumAgeInMonths) {
      if (ageInMonths > maximumAgeInMonths) return false;
    }

    if (!appointmentSlotGroup.address_bounding_boxes) return true;

    const inAtLeastOneBoundingBox = appointmentSlotGroup.address_bounding_boxes.some((boundingBox) => {
      return Primary.pointInPolygon([values.lat, values.lng], boundingBox);
    });
    return inAtLeastOneBoundingBox;
  });

  const onDemandLocations = appointmentSlotGroupData.filter((appointmentSlotGroup) => (
    appointmentSlotGroup.test_location.on_demand
  ));

  const onDemandSelected = onDemandLocations.map(loc => loc.test_location.on_demand && loc.id).includes(values.appointment_slot_group_id);
  const [selectedOnDemandLocation, setSelectedOnDemandLocation] = useState(onDemandSelected);

  const findLocation = (id) => onDemandLocations.find(({id: asgId}) => asgId === id);
  const setOnDemandAppointmentFromLocations = id => setOnDemandAppointment(findLocation(id));

  useEffect(() => {
    // Only one appointmentSlotGroup and no on demand locations
    if (appointmentSlotGroups.length === 1 && !onDemandLocations.length) {
      setFieldValue('appointment_slot_group_id', appointmentSlotGroups[0].id)
    // One on demand location.
    } else if (onDemandLocations.length === 1 && !appointmentSlotGroups.length) {
      setOnDemandAppointment(onDemandLocations[0])
    } else {
      // reset page every time
      setFieldValue('appointment.insist_on_single_dose', null);
      setFieldValue('appointment.refuse_covid_vaccination', null);
      setFieldValue('appointment.insist_on_single_dose', null);
      setFieldValue('appointment.appointment_type', "default");
      setFieldValue("appointment.appointment_slot_id", null);
      setFieldValue("appointment_slot_group_id", null);
    }
  }, [])

  const selectedAppointmentSlotGroup = appointmentSlotGroupData.find(
    appointmentSlotGroup => appointmentSlotGroup.id === values.appointment_slot_group_id
  );

  let firstAppointmentSlots;
  const insistOnSingleDoseOrRefuseCovidVaccine = values.appointment.appointment_type != "default" ||
    values.appointment.refuse_covid_vaccination

  const selectedAppointmentSlot = (selectedAppointmentSlotGroup?.appointment_slots || []).find(
    appointmentSlot => appointmentSlot.id === values.appointment.appointment_slot_id
  );

  if (selectedAppointmentSlotGroup) {
    firstAppointmentSlots = slotter.availableSlotsForAppointmentSlotGroup(selectedAppointmentSlotGroup, !insistOnSingleDoseOrRefuseCovidVaccine);
  }

  return (
    <div className="form-section">
      <SectionTitle className="mb-4">{ t(buildCustomizedLabel(testGroup.population, 'registration.location')) }</SectionTitle>
      {localFormState.showUserAlreadyExistsModal && <UserAlreadyExistsModal />}
      <LocalizedMarkdown container={testGroup} stringKey='appointment_slot_section_description' />
      {step >= 0 && testGroup.appointment_configurations.includes("ask_if_covid_appointment") &&
        <CovidTypeAppointment
          values={values}
          setFieldValue={setFieldValue}
          onClick={(refuseCovidVaccination) => {
            if (showChooseAppointmentType && !refuseCovidVaccination) {
              setStep(1);
            } else {
              setStep(2);
            }
          }}
        />
      }
      {!values.appointment.refuse_covid_vaccination &&
        showChooseAppointmentType &&
        step > 0 &&
        <ChooseAppointmentType
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          onClick={() => setStep(step + 1)}
        />
      }
      {step > 1 &&
        <CardWrapper
          requiresCard={!insistOnSingleDoseOrRefuseCovidVaccine}
        >
          <Row>
            <Col xs={12} lg={6} className="mb-3">
              { onDemandLocations.length > 0 &&
                <React.Fragment>
                  <h5>
                    {buildLocalizedString(testGroup, 'appointment_slot_section_general_enrollment_title') || "General Enrollment"}
                  </h5>
                  {!alwaysCollapse && onDemandLocations.length <= 9 ? (
                    onDemandLocations.map((appointmentSlotGroup, idx) => (
                      <div
                        className="mb-4"
                        key={`pill-${appointmentSlotGroup.id}`}
                      >
                        <ul
                          className="nav flex-column"
                          id="pills-tab"
                          role="tablist"
                        >
                          <AppointmentSlotGroupPill
                            autoFocus={idx === 0}
                            appointmentSlotGroup={appointmentSlotGroup}
                            selected={
                              values.appointment_slot_group_id ===
                              appointmentSlotGroup.id
                            }
                            onClick={() =>
                              setOnDemandAppointment(appointmentSlotGroup)
                            }
                            disabled={appointmentSlotGroupDisabled(
                              values.appointment.appointment_type,
                              appointmentSlotGroup,
                              testGroup.appointment_configurations,
                              values.appointment.refuse_covid_vaccination,
                            )}
                          />
                        </ul>
                      </div>
                    ))
                  ) : (
                    <FormSelect
                      key={`test-locations-select`}
                      fieldName="test-locations-select"
                      prompt={t('registration.location')}
                      options={appointmentSlotGroupsOptions(onDemandLocations)}
                      selected={values.appointment_slot_group_id}
                      onChange={(id) => setOnDemandAppointmentFromLocations(id)}
                    />
                  )}
                  {
                    appointmentSlotGroups.length > 0 &&
                    <React.Fragment>
                      <Row className="mb-4">
                        <Col><hr style={{borderTop: "1px solid black"}}/></Col>
                        <Col xs="auto" style={{alignSelf: "center", fontSize: "18px"}} className="px-0">
                          OR
                        </Col>
                        <Col><hr style={{borderTop: "1px solid black"}}/></Col>
                      </Row>
                      <div>
                        <Button
                          variant="link"
                            className="fw-bold mb-3 px-0"
                            onClick={() => setShowNonOnDemandAppointmentSlotGroups(!showNonOnDemandAppointmentSlotGroups) }
                          >
                            {t('registration.book_one_time_appointment')}
                            {' '}
                            <FontAwesomeIcon icon={showNonOnDemandAppointmentSlotGroups ? faCaretUp : faCaretDown} />
                        </Button>
                      </div>
                    </React.Fragment>
                  }
                </React.Fragment>
              }
              {
                (showNonOnDemandAppointmentSlotGroups || onDemandLocations.length === 0) &&
                <React.Fragment>
                  <div className="h6">{ t('registration.choose_location') }</div>
                  <CollapsableAppointmentSlotGroupSelector
                    selectedAppointmentSlotGroupId={selectedAppointmentSlotGroup?.id}
                    appointmentSlotGroups={appointmentSlotGroups}
                    onDemand={onDemandLocations.length > 0}
                    onSelect={(s) => {
                      const value = s.value;

                      setFieldValue('appointment_slot_group_id', value)
                      setFieldValue('appointment.appointment_slot_id', null)
                      setSelectedOnDemandLocation(false);

                      if (!appointmentSlotsLoaded.find(x => x == value)) {
                        loadApptSlots({
                          id: value,
                          test_location_id: testGroup.appointment_slot_groups.find(x => x.id === value).test_location_id
                        })
                      }
                    }}
                    collapsed={
                      (appointmentSlotGroups.length > 10 || onDemandLocations.length > 0)
                    }
                    appointmentType={values.appointment.appointment_type}
                    appointmentConfigurations={testGroup.appointment_configurations}
                    refuseCovidVaccination={values.appointment.refuse_covid_vaccination}
                  />
                </React.Fragment>
              }
              {onDemandLocations.map (onDemandLocation => {
                  if (onDemandLocation.id === values.appointment_slot_group_id) return (
                  <div className="text-muted mt-4" key={onDemandLocation.id}>
                    <LocalizedMarkdown container={onDemandLocation} stringKey='description' />
                  </div>
                )})
              }
              { values.appointment_slot_group_id && !selectedOnDemandLocation &&
                <div className="my-5">
                  {localFormState.errorMessage &&
                    <Alert
                      variant="danger"
                      dismissible
                      onClose={() => setLocalFormState({
                        ...localFormState, errorMessage: null
                      })}
                    >
                      {localFormState.errorMessage}
                    </Alert>
                  }
                </div>
              }
              {values.appointment_slot_group_id && !selectedOnDemandLocation &&
                <div className="my-5">
                  {localFormState.errorMessage &&
                    <Alert
                      variant="danger"
                      dismissible
                      onClose={() => setLocalFormState({
                        ...localFormState, errorMessage: null
                      })}
                    >
                      {localFormState.errorMessage}
                    </Alert>
                  }
                  <AppointmentSlotSelector
                    onSelect={(s) => setFieldValue('appointment.appointment_slot_id', s.value)}
                    appointmentSlotId={values.appointment.appointment_slot_id}
                    appointmentSlotGroup={selectedAppointmentSlotGroup}
                    appointmentSlots={firstAppointmentSlots}
                    appointmentSlotsLoaded={!!appointmentSlotsLoaded.find(x => x === selectedAppointmentSlotGroup.id)}
                    allowWaitlist={testGroup.allow_waitlist || testGroup.waitlist_only_on_public_registration || testGroup.population.waitlist_only_on_public_registration}
                    isWaitlisting={localFormState.isWaitlisting}
                    onWaitlist={() =>{
                    setLocalFormState({...localFormState, isWaitlisting: true});
                  }}
                  />
                </div>
              }
            </Col>
            <Col xs={12} lg={6} className="tab-pane fade show text-center">
              { selectedAppointmentSlotGroup?.location_type === "location" &&
                selectedAppointmentSlotGroup?.show_map_on_registration &&
                <GoogleMapsWidget
                  title={selectedAppointmentSlotGroup.title}
                  address={selectedAppointmentSlotGroup.address}
                  marker={selectedAppointmentSlotGroup}
                />
              }
            </Col>
          </Row>
        </CardWrapper>
      }
      {errors.appointment_slot && <div id="appointmentSlotErrorDesc" className='text-danger' aria-live="polite">{t(errors.appointment_slot)}</div>}
    </div>
  );
};

export default AppointmentSlot;
