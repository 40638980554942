import React, { useRef, useState } from 'react';
import { Card, OverlayTrigger, Popover } from 'react-bootstrap';
import { faArrowUp } from '@fortawesome/pro-solid-svg-icons';
import { faCalendar, faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import '../../../common/locales/i18n';
import { CalendarPopover } from './NewShow';
import EditAppointmentModal from './EditAppointmentModal';
import { formatAndLocalizeDateTime } from '../../../common/locales/utils';

const AppointmentGroupCard = ({
  appointment,
  t,
  testGroup,
}) => {
  const [showPopover, setShowPopover] = useState(false);
  const [showEditAppointmentModal, setShowEditAppointmentModal] = useState(false);
  const target = useRef(null);
  const localizedStartsAt = formatAndLocalizeDateTime(new Date(appointment.appointment_slot.starts_at), 'eeee, MMMM dd, yyyy');
  return (
    <Card body className='my-4'>
      <EditAppointmentModal
        appointment={appointment}
        appointmentSlot={appointment?.appointment_slot}
        appointmentSlotGroup={appointment.appointment_slot_group}
        onHide={() => setShowEditAppointmentModal(false)}
        selectedServiceNames={appointment.test_configuration_names.join(", ")}
        showEditAppointmentModal={showEditAppointmentModal}
        testGroup={testGroup}
      />
      <a
        className='float-end pointer'
        style={{ color: "#717D96" }}
        onClick={() => {
          setShowEditAppointmentModal(true);
        }}
      >
        <span className="me-1 body-medium-bold inter bold">{t("login.edit_appointment")}</span>
        <FontAwesomeIcon icon={faPenToSquare} />
      </a>
      <div className='poppins semibold h4-20-regular mb-2'>{appointment.user.full_name}</div>
      <div className="d-flex justify-content-start">
        <div>
          <Card
            className='border-0 gray-light-100 text-center text-primary me-4'
            style={{ height: '100px', width: '100px' }}
          >
            <div className="my-auto">
              <div className='inter bold'>
                {appointment.appointment_slot.starts_at_shorten_month}
              </div>
              <div style={{fontSize: 30}} className='inter bold body'>
                {appointment.appointment_slot.starts_at_day}
              </div>
            </div>
          </Card>
        </div>
        <div>
          <div className='inter bold'>{localizedStartsAt || appointment.appointment_slot.starts_at_label}</div>
          <div className='d-sm-none'>{appointment.appointment_slot_group.title}</div>
          <div className='d-none d-sm-block'>
            {appointment.appointment_slot_group.title} 
            <span className='inter semibold lead-20-medium ms-2'>
              <a target="_blank" href={`https://www.google.com/maps/place/${appointment.appointment_slot_group.address}`}>
                Map <FontAwesomeIcon icon={faArrowUp} className="rotate-45" />
              </a>
            </span>
          </div>
          {!!appointment.test_configuration_names?.length && (
            <div>
              <span className='inter bold'>Services: </span>
              <span>{appointment.test_configuration_names.join(", ")}</span>
            </div>
          )}
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="bottom"
              onToggle={(show) => setShowPopover(show)}
              overlay={<Popover className="d-none" />} // Dummy overlay required for popover to disappear on blur
            >
              <a
                aria-expanded={showPopover}
                className={`body-medium-bold inter bold pointer`}
                ref={target}
              >
                <FontAwesomeIcon icon={faCalendar} className="me-2" />{t('instructions.add_to_calendar')}
              </a>
            </OverlayTrigger>
          <CalendarPopover target={target} show={showPopover} />
        </div>
      </div>
    </Card>
  ) 
}

const AppointmentGroupCards = ({
 appointmentGroup,
 testGroup,
}) => {
  const { t } = useTranslation();
  const upcomingAppointments = appointmentGroup?.appointments?.filter((a) => a.appointment_slot?.is_upcoming) || [];
  if (testGroup.on_demand_only || !upcomingAppointments.length) return <></>;
  return (
    <>
      <div className='text-muted inter medium mb-2'>{t('instructions.upcoming_appointments')}</div>
      {upcomingAppointments
        .map((appointment, index) => (
          <AppointmentGroupCard
            appointment={appointment}
            key={index}
            t={t}
            testGroup={testGroup}
          /> 
        )
      )}
    </>
  );

};


export default AppointmentGroupCards;
