import React from "react";

const VaccineLots = ({
  test_group: {
    appointment_slot_group_links_last,
    appointment_slot_links_first,
    more_than_five_groups,
  },
}) => {
  return (
    <>
      <p>Activate and deactivate vaccine lots by location</p>
      {appointment_slot_links_first.map((link) => (
        <div className="my-3">
          <a href={link.link}><strong>{link.title}</strong></a>
        </div>
      ))}
      {more_than_five_groups && (
        <>
          <div id="show-more-lots" className="collapse mt-3">
            {appointment_slot_group_links_last.map((link) => (
              <div className="my-3">
                <a href={link.link}><strong>{link.title}</strong></a>
              </div>
            ))}
          </div>
          <a
            id="show-more-lots-button"
            className="button default ok mt-3 font-weight-bold"
            href="#show-more-lots"
            data-bs-toggle="collapse"
            aria-expanded="false"
          >
            Show more +
          </a>
        </>
      )}
    </>
  );
};

export default VaccineLots;