import React, { useEffect, useState, useRef } from 'react';
import Select, {
  CheckboxOption,
} from '../../common/components/Select';
import { components } from 'react-select';

const ValueContainer = ({ ...props }) => {
  const selectedValues = props.getValue();
  const label = selectedValues.map((item) => item.label).join(", ") || props.selectProps.placeholder;
  return (
    <components.ValueContainer {...props} >
      <div
        className="text-muted"
        style={{
          display: "inline-block",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: "100%",
          height: "100%"
        }}
        onClick={() => {
          props.selectProps.menuIsOpen
            ? props.selectProps.onMenuClose()
            : props.selectProps.onMenuOpen()
        }}
      >
        {label}
      </div>
    </components.ValueContainer>
  );
};

const StatusDropdown = ({ godUser, status, updateFilter }) => {
  const STATUS_OPTIONS = [
    { value: '2', label: 'Active' },
    { value: '0', label: 'Demo' },
    { value: '1', label: 'Draft' },
    { value: '4', label: 'Read only' },
    { value: '3', label: 'Archived' },
    ...(godUser ? [{ value: '6', label: 'Template' }] : []),
  ];

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <Select
        id="test_group_status_select"
        name="test_group_status"
        ariaLabel="test_group_status select"
        closeMenuOnSelect={false}
        components={{
          Option: CheckboxOption,
          ValueContainer,
        }}
        hideSelectedOptions={false}
        isClearable={false}
        isMulti
        onChange={values => updateFilter('status', values.join(","))}
        options={STATUS_OPTIONS}
        placeholder="Group status"
        value={status?.split(",")}
        valueKey="value"
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => setIsOpen(false)}
        menuIsOpen={isOpen}
      />
    </div>
  );
};

export default StatusDropdown;
