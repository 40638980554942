import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faPrint } from '@fortawesome/pro-solid-svg-icons';
import { Calendar } from 'react-multi-date-picker';
import format from 'date-fns/format';
import max from 'date-fns/max';
import {
  Container,
  Row,
  Col,
  Button,
  Badge,
  Collapse,
  Card,
  Spinner,
  Alert,
  Modal,
} from 'react-bootstrap';
import ModalHeader from '../../common/components/ModalHeader';
import UpdateVaccineResult from './UpdateVaccineResult';
import ErrorModal from './ErrorModal';
import StickyHeader, { STICKY_STYLE } from '../../common/components/StickyHeader';
import AlertHeader from '../../common/components/AlertHeader';
import FabrxCheckbox from '../../Registration/primary/FabrxCheckbox';
import FloatingLabelInput from '../../common/components/FloatingLabelInput';
import ImmunizationHistory from './ImmunizationHistory';
import { checkoutShape } from './shapes';
import { onlyUnique, withinAgeRange } from '../../common/utils';
import { getTestResultType } from '../../shared_labs/utils';
import CreateTestOfType, { SimpleCreateTestOfType } from './CreateTestOfType';
import {
  additionalConsents,
  CheckInInputs,
} from '../../Participants/components/participants/NewCheckInModal';
import UpdateTestForm, {
  getResultsLink,
  hasGenericLabModule,
  resultAtCheckoutEligible,
} from './UpdateTestForm';
import {
  CheckoutErrors,
  ConfirmationModal,
  errorRequiresConfirmation,
  insuranceInfoErrored,
} from './UserErrors';
import ServiceCard from './ServiceCard';
import ServicesSection from './ServicesSection';

const redirectToResultingPage = (testConfiguration, uid) => {
  const path = getResultsLink(testConfiguration.service_specification);

  if (path) {
    window.location = hasGenericLabModule(
      testConfiguration.service_specification,
    )
      ? `/${path}_test_results/${uid}`
      : `/${path}/results/${uid}`;
  }
};

export const validateCode = (testConfiguration, uid) => {
  if (uid.length < 1) return false;
  const labAcceptedFormat = validateOnFormats(uid, '^[A-Za-z0-9_-]*$', true);
  if (!testConfiguration.require_specific_code_format) return labAcceptedFormat;
  return (
    validateOnFormats(uid, testConfiguration.acceptable_code_formats, true) &&
    validateOnFormats(uid, testConfiguration.rejectable_code_formats, false) &&
    labAcceptedFormat
  );
};

const validateOnFormats = (uid, codeFormats, isAcceptable) => {
  if (!codeFormats) return true;
  return isAcceptable
    ? uid.match(new RegExp(codeFormats))
    : !uid.match(new RegExp(codeFormats));
};

const PreCheckinSections = ({
  testGroup,
  appointment,
  symptoms,
  tests,
  currentUser,
  checkinOptions,
  covid19Vaccination,
  testConfiguarations = [],
  setSurveyMissingAnswer,
}) => {
  const { user } = appointment;

  return (
    <React.Fragment>
      {checkinOptions.length > 0 && (
        <form
          encType="multipart/form-data"
          action={`/test_groups/${testGroup.id}/appointments/${appointment.id}`}
          acceptCharset="UTF-8"
          method="post"
          id="checkInForm"
        >
          <CheckInInputs
            testGroup={testGroup}
            testConfigurations={testConfiguarations}
            appointment={appointment}
            user={user}
            tests={tests}
            symptoms={symptoms}
            activeSections={checkinOptions}
            covid19Vaccination={covid19Vaccination}
            disableAdvanced={true}
            setSurveyMissingAnswer={setSurveyMissingAnswer}
            checkOut={true}
          />
        </form>
      )}
      {appointment.chosen_test_configurations.length > 0 && (
        <React.Fragment>
          {checkinOptions.length > 0 && <hr />}
          <h5>Chosen Test Services</h5>
          {appointment.chosen_test_configurations.map((testConfiguration) => (
            <Badge
              style={{ backgroundColor: testConfiguration.color_code }}
              variant="dark"
              className="me-2"
              key={testConfiguration.id}
            >
              {testConfiguration.display_name}
            </Badge>
          ))}
        </React.Fragment>
      )}
      {testGroup.checkout_options.includes('show_immunization_history') && (
        <>
          {(checkinOptions.length > 1 ||
            appointment.chosen_test_configurations.length > 0) && <hr />}
          <ImmunizationHistory
            testGroupId={testGroup.slug}
            user={user}
            isHipaaTrained={currentUser.is_hipaa_trained}
            tests={appointment.tests}
            histories={user.vaccine_histories}
            iisReferences={user.iis_references}
          />
        </>
      )}
    </React.Fragment>
  );
};

const sortTests = (tests) =>
  tests.sort((ta, tb) => {
    if (!ta.result && tb.result && tb.administered_at) return -1;
    if (ta.result && ta.administered_at && !tb.result) return 1;
    return 0;
  });

const additonalConsentError = (testConfigurations, appointment, tests = []) => {
  const activeTestConfigurations = tests
    .filter((test) => test.administered)
    .map((test) => test.test_configuration_id);

  const testGroupTestConfigurationConsents = additionalConsents(
    testConfigurations,
  ).filter((consent) =>
    activeTestConfigurations.includes(consent.testConfiguration.id),
  );

  const additionalConsentErrors = testGroupTestConfigurationConsents
    .map((additionalConsent) => {
      const consentFormUser = appointment.user.consent_forms_users.find(
        (consentFormUser) =>
          consentFormUser.consent_form_id == additionalConsent.id,
      );
      return (
        !consentFormUser &&
        !consentFormUser?.consented &&
        additionalConsent.testConfiguration.display_name
      );
    })
    .filter((x) => x);

  if (additionalConsentErrors.length == 0) return null;

  return true;
};

const CheckoutPage = ({
  test_group,
  test_configurations,
  appointment,
  current_user,
  symptoms,
  covid_19_vaccination,
  vaccine_service_specifications,
  permission_kinds,
  editable_test_types,
}) => {
  const [tests, _setTests] = useState(sortTests(appointment.tests));
  const setTests = (tests) => _setTests(sortTests(tests));
  const activeTests = tests.filter(
    (test) => !test.result || test.administered == null,
  );
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [spinner, showSpinner] = useState(false);
  const [error, setError] = useState('');
  const [unsavedUidByTestConfigurationId, setUnsavedUidByTestConfigurationId] =
    useState({});
  const [surveyMissingAnswer, setSurveyMissingAnswer] = useState(false);

  const setUnsavedScan = (testConfigurationId, scan) => {
    const newUnsavedScansByTestConfigurationId = {
      ...unsavedUidByTestConfigurationId,
    };
    newUnsavedScansByTestConfigurationId[testConfigurationId] = scan;

    setUnsavedUidByTestConfigurationId(newUnsavedScansByTestConfigurationId);
  };

  const specificTestConfigurations = test_configurations.filter(
    (tc) => tc.service_specification !== 'rapid_general',
  );
  const useServicesModalView = specificTestConfigurations.length >= 9;
  const [showServicesModal, setShowServicesModal] = useState(false);
  const [selectedTestConfigurationIds, setSelectedTestConfigurationIds] =
    useState(
      useServicesModalView ? [] : specificTestConfigurations.map((tc) => tc.id),
    );
  const [servicesViewIcon, setServicesViewIcon] = useState({
    type: 'list',
    url: '/images/icons/icon-list-column-switch-1.svg',
  });

  const [currentAdministratorName, setCurrentAdministratorName] =
    useState(null);
  const [currentAdministratorDegree, setCurrentAdministratorDegree] =
    useState(null);
  const [currentAdministratorInitials, setCurrentAdministratorInitials] =
    useState(null);

  const switchServicesViewIcon = () => {
    servicesViewIcon.type === 'list'
      ? setServicesViewIcon({
          type: 'column',
          url: '/images/icons/icon-list-column-switch-2.svg',
        })
      : setServicesViewIcon({
          type: 'list',
          url: '/images/icons/icon-list-column-switch-1.svg',
        });
  };

  const { user } = appointment;
  const [showAddTests, setShowAddTests] = useState(
    tests.length === 0 || useServicesModalView,
  );

  const isVaccine = (specification) => {
    return vaccine_service_specifications.includes(specification);
  };

  const hasInvalidTestUid = tests.filter(t => !!t.id).some(
    (test) => !validateCode(test.test_configuration, test.uid),
  );

  const updateTest = (test) => {
    setUnsavedChanges(true);
    let index = tests.map((t) => t.id).indexOf(test.id);

    // Concat the front, with the new test with the back
    setTests(
      tests
        .slice(0, index)
        .concat([test])
        .concat(tests.slice(index + 1, tests.length)),
    );
  };

  const redirectToLink = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const redirectTo = urlParams.get('redirect_to');

    if (redirectTo) {
      return redirectTo;
    }
    return `/test_groups/${test_group.slug}/participants`;
  };

  const saveDataToLocalStorage = () => {
    // sticky message that is displayed after saving and redirecting to participants page
    const orderAdministeredMessage = `<a href="appointments/${appointment.id}/checkout">
      Order administered for ${user.first_name} ${user.last_name}.
      Need to make a change? Click here to return to ${user.first_name}
      ${user.last_name}'s checkout.</a>`;

    localStorage.setItem('orderAdministeredMessage', orderAdministeredMessage);
  };

  const addTest = (testConfigurationId, uid = null) => {
    setUnsavedChanges(true);
    axios
      .post(
        `/test_groups/${test_group.slug}/appointments/${appointment.id}/checkout`,
        {
          test_configuration_id: testConfigurationId,
          uid,
        },
      )
      .then((response) => {
        const newTest = response.data;
        const { service_specification, generate_record_configuration } =
          newTest.test_configuration;
        const resultAtCheckout = resultAtCheckoutEligible(
          newTest.test_configuration,
        );

        if (!resultAtCheckout) {
          const showResultImmediately =
            generate_record_configuration === 'shows_result_immediately';

          if (!isVaccine(service_specification) || showResultImmediately)
            newTest.administered = true;

          if (showResultImmediately) {
            submitTests([newTest]);
            setUnsavedChanges(false);
            redirectToResultingPage(newTest.test_configuration, newTest.uid);
          }
        }

        setTests([...tests, newTest]);
      })
      .catch((error) => {
        setError(error.response?.data?.error);
        setShowAddTests(true);
      });
  };

  const addTests = (testConfigurationId, quantity = 1) => {
    axios
      .post(
        `/test_groups/${test_group.slug}/appointments/${appointment.id}/checkout/batch_create`,
        {
          test_configuration_id: testConfigurationId,
          quantity,
        },
      )
      .then((response) => {
        let newTests = response.data;
        newTests = newTests.map((newTest) => ({
          ...newTest,
          administered: true,
        }));
        setTests([...tests, ...newTests]);
        setUnsavedChanges(true);
      })
      .catch((error) => {
        setError(error.response?.data?.error);
        setShowAddTests(true);
      });
  };

  const submitTests = (
    tests,
    bypassInitials = null,
  ) => {
    const body = {
      appointment_id: appointment.id,
      bypassed_consent: !!bypassInitials,
      tests,
    };
    return axios.post(
      `/test_groups/${test_group.slug}/tests_batch_create`,
      body,
    );
  };

  const handleCheckinSubmit = () => {
    const form = document.querySelector('#checkInForm');
    const formData = new FormData(form);

    axios({
        url: `/test_groups/${test_group.id}/appointments/${appointment.id}`,
        method: "post",
        data: formData,
        headers: { 'content-type': 'multipart/form-data' },
      });
  };

  const checkinInCheckoutOptions = test_group.checkout_options
    .filter((opt) => test_group.all_checkin_options.includes(opt))
    .concat(test_group.on_demand_only ? ['consent'] : [])
    .filter(onlyUnique);

  const saveTestsAndCloseModal = (initials) => {
    hideConfirmationModal();
    saveTests(false, true, initials);
  };

  const saveTests = (
    force,
    redirectAfterSubmit = true,
    bypassInitials = null,
  ) => {
    // Build the tests that are not saved yet.
    const vaccinesWithoutAdministered = tests
      .filter((t) => isVaccine(t.test_configuration.service_specification))
      .filter((t) => t.administered === null);
    if (vaccinesWithoutAdministered.length > 0 && !force) {
      // Show modal
      setShowErrorModal(true);
      return;
    }

    // Update tests that do not show the administer button
    tests
      .filter((t) => t.test_configuration.hide_administered_button)
      .map((t) => updateTest({ ...t, administered: true }));

    let unsavedTests = [];
    for (const [testConfigurationId, uid] of Object.entries(
      unsavedUidByTestConfigurationId,
    )) {
      if (uid && uid.length > 4) {
        unsavedTests.push({
          test_configuration_id: testConfigurationId,
          appointment_id: appointment.id,
          uid: uid,
        });
      }
    }
    setUnsavedChanges(false);

    const appointmentId = appointment.id;

    if (checkinInCheckoutOptions.length > 0) handleCheckinSubmit();

    const anyTestGotAdministered = tests.some((test) => test['administered']);

    submitTests(
      tests.concat(unsavedTests),
      {
        new_date: rescheduleDate,
        id: appointmentId,
      },
      bypassInitials,
    )
      .then((_response) => {
        if (redirectAfterSubmit) {
          if (anyTestGotAdministered) saveDataToLocalStorage();
          location.href = redirectToLink();
        }
      })
      .catch((error) => {
        alert('Something went wrong: ' + error.response.data.message);
      });
  };

  const buildConsentParams = (test) => ({
    consentFormsUsers: appointment.user.consent_forms_users,
    consentForm: test_configurations.find(
      (tc) => tc.id == test.test_configuration_id,
    ).test_group_test_configuration.active_consent_form,
  });

  const [redirectCallbackPath, setRedirectCallbackPath] = useState(null);

  useEffect(() => {
    if (redirectCallbackPath) {
      location.href = redirectCallbackPath;
    }
  }, [redirectCallbackPath]);

  const [rescheduleDate, setRescheduleDate] = useState(null);
  const rescheduleText = 'Reschedule appointment';

  const confirmationError = additonalConsentError(
    test_configurations,
    appointment,
    tests,
  )
    ? 'test_group_test_configuration_error'
    : errorRequiresConfirmation(user.errors);
  const missingInsuranceInfo =
    test_group.insurance_information_required &&
    insuranceInfoErrored(user.errors);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [insuranceConfirmationModalOpen, setInsuranceConfirmationModalOpen] =
    useState(missingInsuranceInfo);

  const showConfirmationModal = () => {
    setConfirmationModalOpen(true);
  };
  const hideConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };
  const saveRecordsButtonOnClick = confirmationError
    ? showConfirmationModal
    : () => saveTests(false);

  const disabledCheckoutButton =
    hasInvalidTestUid ||
    Object.values(unsavedUidByTestConfigurationId).some(
      (test) => test !== '',
    ) ||
    (!unsavedChanges && !test_group.flipper_flags.checkout_services_v2) ||
    (test_group.flipper_flags.checkout_services_v2 && !tests.length) ||
    surveyMissingAnswer ||
    (test_group.flipper_flags.do_not_bypass_consent_on_checkout &&
      confirmationError);

  const lastUpdatedTime = () => {
    try {
      const updatedTime = max(tests.map((t) => new Date(t.updated_at)));
      return format(updatedTime, 'MM/dd/yyyy h:mm aaa');
    } catch {
      return 'N/A';
    }
  };

  const header = (
    <div>
      <Row className="justify-content-between flex-row-reverse mt-5">
        <Col className="text-muted mb-2" xs="auto" style={{ alignSelf: 'end' }}>
          Last updated: {lastUpdatedTime()}
        </Col>
        <Col lg={8}>
          <h3>
            New visit for {user.first_name} {user.last_name}
          </h3>
        </Col>
      </Row>
      <Row>
        <h5>
          {user.date_of_birth} (age: {user.age})
        </h5>
      </Row>
    </div>
  );

  const checkoutHeader = (
    <AlertHeader
      title="Incomplete checkout"
      message="In order to process results of administered services, please review all information and complete checkout"
      btnTxt="Complete checkout"
      btnAction={() => {
        saveRecordsButtonOnClick();
        showSpinner(true);
      }}
      type="info"
      disabledBtn={disabledCheckoutButton}
    />
  );

  const checkoutErrors = (
    <CheckoutErrors
      appointment={appointment}
      test_group_id={test_group.slug}
      user={user}
    />
  );

  const SelectableTestConfiguration = ({
    testConfiguration,
    checkBoxChecked,
    addTestConfiguration,
    removeTestConfiguration,
  }) => {
    const [checked, setChecked] = useState(checkBoxChecked);
    return (
      <div
        className="p-3 my-2 pointer"
        style={{ border: '1px solid #DFE2E6', borderRadius: '8px' }}
        onClick={() => {
          if (checked) {
            removeTestConfiguration(testConfiguration.id);
            setChecked(false);
          } else {
            addTestConfiguration(testConfiguration.id);
            setChecked(true);
          }
        }}
      >
        <div className="d-flex justify-content-start">
          <FabrxCheckbox
            checked={checked}
            dataTestHook={
              'selectable_test_configuration_' + testConfiguration.id
            }
          />
          <div>
            <span style={{ color: testConfiguration.color_code }}>
              <FontAwesomeIcon
                icon={['fa-regular', testConfiguration.font_awesome_icon]}
              />
            </span>
            <span className="ms-1">
              {testConfiguration.checkout_name ||
                testConfiguration.display_name}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const ServicesModal = ({
    showServicesModal,
    setShowServicesModal,
    selectedTestConfigurationIds,
    setSelectedTestConfigurationIds,
    specificTestConfigurations,
  }) => {
    const [
      newSelectedTestConfigurationIds,
      setNewSelectedTestConfigurationIds,
    ] = useState(selectedTestConfigurationIds);
    const [filteredTestConfigurationIds, setFilteredTestConfigurationIds] =
      useState(specificTestConfigurations.map((tc) => tc.id));
    const [searchInput, setSearchInput] = useState('');

    const addTestConfiguration = (testConfigurationId) => {
      if (!newSelectedTestConfigurationIds.includes(testConfigurationId)) {
        const updatedTestConfigurationIds =
          newSelectedTestConfigurationIds.concat(testConfigurationId);
        setNewSelectedTestConfigurationIds(updatedTestConfigurationIds);
      }
    };

    const removeTestConfiguration = (testConfigurationId) => {
      const updatedTestConfigurationIds = [...newSelectedTestConfigurationIds];
      const index = updatedTestConfigurationIds.indexOf(testConfigurationId);
      if (index > -1) updatedTestConfigurationIds.splice(index, 1);
      setNewSelectedTestConfigurationIds(updatedTestConfigurationIds);
    };

    const processSearchResults = (searchInput) => {
      setSearchInput(searchInput);
      const processedSearchInput = searchInput.trim().toUpperCase();
      const filteredIds = specificTestConfigurations
        .filter((tc) => {
          return (
            tc.checkout_name
              ?.trim()
              .toUpperCase()
              .indexOf(processedSearchInput) > -1 ||
            tc.display_name
              ?.trim()
              .toUpperCase()
              .indexOf(processedSearchInput) > -1
          );
        })
        .map((tc) => tc.id);
      setFilteredTestConfigurationIds(filteredIds);
    };

    return (
      <Modal
        show={showServicesModal}
        onHide={() => setShowServicesModal(false)}
        size="lg"
      >
        <ModalHeader>
          <Modal.Title as="h2">Add Service</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <FloatingLabelInput
            className="mb-3"
            label="Search"
            dataTestHook="search_services"
            onChange={(e) => processSearchResults(e.target.value)}
            value={searchInput}
            prependIcon="search"
          />
          {specificTestConfigurations
            .filter((tc) => filteredTestConfigurationIds.includes(tc.id))
            .map((testConfiguration) => {
              return (
                <div key={testConfiguration.id}>
                  <SelectableTestConfiguration
                    testConfiguration={testConfiguration}
                    checkBoxChecked={newSelectedTestConfigurationIds.includes(
                      testConfiguration.id,
                    )}
                    newSelectedTestConfigurationIds={
                      newSelectedTestConfigurationIds
                    }
                    setNewSelectedTestConfigurationIds={
                      setNewSelectedTestConfigurationIds
                    }
                    addTestConfiguration={addTestConfiguration}
                    removeTestConfiguration={removeTestConfiguration}
                  />
                </div>
              );
            })}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            className="mx-2"
            onClick={() => setShowServicesModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="mx-2"
            disabled={newSelectedTestConfigurationIds < 1}
            data-test-hook="add_service"
            onClick={() => {
              setSelectedTestConfigurationIds(newSelectedTestConfigurationIds);
              setShowServicesModal(false);
            }}
          >
            Add Service
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const { contentRef, isSticky } = StickyHeader();

  return (
    <Container fluid="md" className="mt-3 h5-vivid-blue" id="new-checkout">
      {!test_group.active &&
        test_configurations.some((x) =>
          getTestResultType(x.service_specification),
        ) && (
          <Alert variant="danger">
            Warning: This group is not active for global test search, please
            update
            <a
              href={`/test_groups/${test_group.slug}/edit#active_badge`}
              className="mx-1"
            >
              here
            </a>
            in order to log services
          </Alert>
        )}
      {showErrorModal && (
        <ErrorModal
          handleClose={() => {
            showSpinner(false);
            setShowErrorModal(false);
          }}
          errors={{
            message: 'Are you sure? The vaccine administration is not logged.',
          }}
          submit={saveTests.bind(null, true)}
        />
      )}

      {checkoutErrors}
      {!appointment['checked_out?'] && checkoutHeader}
      {isSticky && (
        <div
          className="sticky pt-2"
          style={STICKY_STYLE}
        >
          <Container fluid="md">
            {!appointment['checked_out?'] && checkoutHeader}
          </Container>
        </div>
      )}

      <div ref={contentRef}>
        {header}
        <PreCheckinSections
          testGroup={test_group}
          appointment={appointment}
          symptoms={symptoms}
          tests={tests}
          currentUser={current_user}
          checkinOptions={checkinInCheckoutOptions}
          covid19Vaccination={covid_19_vaccination}
          testConfiguarations={test_configurations}
          setSurveyMissingAnswer={setSurveyMissingAnswer}
        />
        <hr />
        {test_group.flipper_flags.checkout_services_v2 ? (
          <ServicesSection
            appointment={appointment}
            currentAdministratorDegree={currentAdministratorDegree}
            currentAdministratorInitials={currentAdministratorInitials}
            currentAdministratorName={currentAdministratorName}
            editableTestTypes={editable_test_types}
            permissionKinds={permission_kinds}
            saveTests={saveTests}
            setCurrentAdministratorDegree={setCurrentAdministratorDegree}
            setCurrentAdministratorInitials={setCurrentAdministratorInitials}
            setCurrentAdministratorName={setCurrentAdministratorName}
            setRedirectCallbackPath={setRedirectCallbackPath}
            setTests={setTests}
            submitTests={submitTests}
            testConfigurations={test_configurations}
            testGroup={test_group}
            tests={tests}
            updateTest={updateTest}
          />
        ) : (
          <>
            {error && <Alert variant="danger">{error}</Alert>}
            <h5 className="my-4">Services</h5>
            <div>
              <div className="text-muted m-2">
                {activeTests.length > 0 ? (
                  <>
                    {activeTests.length} active service
                    {activeTests.length > 1 && 's'}
                  </>
                ) : (
                  'No active services'
                )}
              </div>
              {tests.map((test, index) => {
                const qualified = test.test_configuration.calculate_age_gating_in_days ? (
                  withinAgeRange(
                    user.age_in_days,
                    test.test_configuration.minimum_age_in_days,
                    test.test_configuration.maximum_age_in_days,
                  )
                ) : (
                  withinAgeRange(
                    user.age_in_months,
                    test.test_configuration.minimum_age_in_months,
                    test.test_configuration.maximum_age_in_months,
                  )
                );
                if (['vitals_screening', 'hypertension_screening'].includes(test.test_configuration.service_specification)) {
                  return (
                    <ServiceCard
                      containerClassName="my-3"
                      color={test.test_configuration.color_code}
                      key={test.id}
                      title={test.test_configuration.display_name}
                      tag="Wellness"
                      serviceSpecification={test.test_configuration.service_specification}
                      test={test}
                      updateTest={updateTest}
                      submitTests={submitTests}
                      saveTests={saveTests}
                    />
                  )
                } else {
                  return isVaccine(test.test_configuration.service_specification) ? (
                    <div key={test.id} className="my-3">
                      <UpdateVaccineResult
                        test={test}
                        updateTest={updateTest}
                        submitTests={submitTests}
                        qualified={qualified}
                        consent={buildConsentParams(test)}
                        currentAdministratorName={currentAdministratorName}
                        setCurrentAdministratorName={setCurrentAdministratorName}
                        currentAdministratorDegree={currentAdministratorDegree}
                        setCurrentAdministratorDegree={setCurrentAdministratorDegree}
                        currentAdministratorInitials={currentAdministratorInitials}
                        setCurrentAdministratorInitials={
                          setCurrentAdministratorInitials
                        }
                      />
                    </div>
                  ) : (
                    <div
                      key={test.id}
                      data-test-hook={`test-${test.id}`}
                      className="my-3"
                    >
                      <UpdateTestForm
                        key={test.id}
                        test={test}
                        updateTest={updateTest}
                        submitTests={submitTests}
                        saveTests={saveTests}
                        setRedirectCallbackPath={setRedirectCallbackPath}
                        isGroupOnDemandOnly={test_group.on_demand_only}
                        qualified={qualified}
                        consent={buildConsentParams(test)}
                        permission_kinds={permission_kinds}
                        editable_test_types={editable_test_types}
                      />
                    </div>
                  );
                }
              })}
            </div>
            <Collapse in={showAddTests}>
              <div>
                <div className="d-flex justify-content-between my-4">
                  {useServicesModalView ? (
                    <Button
                      variant="outline-primary"
                      onClick={() => setShowServicesModal(true)}
                      className="my-2"
                    >
                      Add Service
                    </Button>
                  ) : (
                    <h5>Add services</h5>
                  )}
                  <div className="d-flex flex-column justify-content-center">
                    <div className="d-flex justify-content-center">
                      <img
                        src={servicesViewIcon.url}
                        className="pointer"
                        data-test-hook="service_view_icon"
                        onClick={() => switchServicesViewIcon()}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  {servicesViewIcon.type === 'column' ? (
                    <Row className="d-flex justify-content-start mb-3">
                      {specificTestConfigurations
                        .filter((tc) =>
                          selectedTestConfigurationIds.includes(tc.id),
                        )
                        .map((testConfiguration, idx) => {
                          return (
                            <Col key={testConfiguration.id} md="6">
                              <SimpleCreateTestOfType
                                unsavedUid={
                                  unsavedUidByTestConfigurationId[
                                    testConfiguration.id
                                  ] || ''
                                }
                                setUnsavedUid={setUnsavedScan.bind(
                                  null,
                                  testConfiguration.id,
                                )}
                                hideComponent={() =>
                                  setShowAddTests(useServicesModalView)
                                }
                                addTest={addTest}
                                addTests={addTests}
                                appointment={appointment}
                                testConfiguration={testConfiguration}
                                isVaccine={isVaccine(
                                  testConfiguration.service_specification,
                                )}
                                consentFormsUsers={
                                  appointment.user.consent_forms_users
                                }
                                permission_kinds={permission_kinds}
                                editable_test_types={editable_test_types}
                              />
                            </Col>
                          );
                        })}
                    </Row>
                  ) : (
                    <div>
                      {specificTestConfigurations
                        .filter((tc) =>
                          selectedTestConfigurationIds.includes(tc.id),
                        )
                        .map((testConfiguration, idx) => {
                          return (
                            <Row
                              key={idx}
                              className="d-flex justify-content-start mb-3"
                            >
                              <Col key={testConfiguration.id}>
                                <CreateTestOfType
                                  unsavedUid={
                                    unsavedUidByTestConfigurationId[
                                      testConfiguration.id
                                    ] || ''
                                  }
                                  setUnsavedUid={setUnsavedScan.bind(
                                    null,
                                    testConfiguration.id,
                                  )}
                                  hideComponent={() =>
                                    setShowAddTests(useServicesModalView)
                                  }
                                  addTest={addTest}
                                  addTests={addTests}
                                  appointment={appointment}
                                  testConfiguration={testConfiguration}
                                  isVaccine={isVaccine(
                                    testConfiguration.service_specification,
                                  )}
                                  consentFormsUsers={
                                    appointment.user.consent_forms_users
                                  }
                                  permission_kinds={permission_kinds}
                                  editable_test_types={editable_test_types}
                                />
                              </Col>
                            </Row>
                          );
                        })}
                    </div>
                  )}
                </div>
              </div>
            </Collapse>
          </>
        )}

        <div className="float-end mt-4">
          {test_group.checkout_options.includes(
            'enable_print_all_vaccines',
          ) && (
            <div className="text-end">
              <Button
                href={`/print_labels?label_type=vaccines_administered&appointment_id=${appointment.id}`}
                target="_blank"
                variant="link"
                className="mb-4"
              >
                <FontAwesomeIcon icon={faPrint} className="me-1" />
                Print all vaccines
              </Button>
            </div>
          )}
        </div>
        <div style={{ clear: 'both' }}></div>
        {hasInvalidTestUid && (
          <Row className="mt-4 mb-2 justify-content-md-center">
            <Col lg="8">
              <label
                className="form-label center"
                style={{ color: 'red', fontSize: '20px' }}
              >
                At least one of the barcodes does not match the format for the
                service. Please confirm the barcode and the service type.
              </label>
            </Col>
          </Row>
        )}
        <div className="text-end my-5">
          {spinner ? (
            <Spinner variant="primary" animation="border" />
          ) : (
            <Row className="justify-content-between">
              <Col xl={3} lg={4} md={6} sm={12}>
                {!useServicesModalView && tests.length > 0 && !test_group.flipper_flags.checkout_services_v2 && (
                  <Button
                    variant="outline-primary"
                    onClick={() => setShowAddTests(!showAddTests)}
                    aria-expanded={showAddTests}
                    className="my-2"
                    block
                  >
                    <FontAwesomeIcon
                      icon={showAddTests ? faMinus : faPlus}
                      className="me-2"
                    />{' '}
                    Add Service
                  </Button>
                )}
              </Col>
              <Col xl={3} lg={4} md={6} sm={12}>
                <Button
                  disabled={disabledCheckoutButton}
                  onClick={() => {
                    saveRecordsButtonOnClick();
                    showSpinner(true);
                  }}
                  variant="primary"
                  data-test-hook="save"
                  className="my-2"
                  block
                >
                  Complete checkout
                </Button>
                {surveyMissingAnswer && (
                  <div className="small text-danger mt-2">
                    Please complete the required survey questions above.
                  </div>
                )}
                {!unsavedChanges && (
                  <div className="small text-muted mt-2">No changes made</div>
                )}
              </Col>
            </Row>
          )}
        </div>
        {confirmationError && (
          <ConfirmationModal
            error={confirmationError}
            userErrors={user.errors}
            show={confirmationModalOpen}
            onHide={hideConfirmationModal}
            onSave={(initials) => saveTestsAndCloseModal(initials)}
            currentUserInitials={current_user.initials}
          />
        )}
        {missingInsuranceInfo && (
          <ConfirmationModal
            error="insurance_info"
            userErrors={user.errors}
            show={insuranceConfirmationModalOpen}
            onHide={() => setInsuranceConfirmationModalOpen(false)}
          />
        )}
        {useServicesModalView && (
          <ServicesModal
            showServicesModal={showServicesModal}
            setShowServicesModal={setShowServicesModal}
            selectedTestConfigurationIds={selectedTestConfigurationIds}
            setSelectedTestConfigurationIds={setSelectedTestConfigurationIds}
            specificTestConfigurations={specificTestConfigurations}
          />
        )}
      </div>
    </Container>
  );
};

CheckoutPage.propTypes = checkoutShape;

export default CheckoutPage;
